<template>
  <div class="bg-footer container-fluid py-3 footer-height">
    <div class="container-modify">
      <div class="container-content">
        <div class="container-content-aff">
          <div class="container-logos">
            <img src="/img/logo.png" alt="logo de linetor" style="filter: drop-shadow(rgba(0, 0, 0, 0.7) 1px 1px 4px);"/>
            <div class="aff">
              <img class="p-2" width="50px" src="/img/icons/Airbnbscuadrado.png" alt="logo de airbnb" style="filter: drop-shadow(rgba(0, 0, 0, 0.7) 1px 1px 4px);"/>          
              <img class="p-2" width="50px" src="/img/icons/Bookingcuadrado.png" alt="logo de booking" style="filter: drop-shadow(rgba(0, 0, 0, 0.7) 1px 1px 4px);"/>
              <img class="p-2" width="50px" src="/img/icons/Vrbocuadrado.png" alt="logo de vrbo" style="filter: drop-shadow(rgba(0, 0, 0, 0.7) 1px 1px 4px);"/>
              <img class="p-2" width="50px" src="/img/icons/Trapadvisorcuadrado.png" alt="logo de Tripadvisor" style="filter: drop-shadow(rgba(0, 0, 0, 0.7) 1px 1px 4px);"/>
              <img class="p-2" width="50px" src="/img/icons/expediacuadrado.png" alt="logo de expedia" style="filter: drop-shadow(rgba(0, 0, 0, 0.7) 1px 1px 4px);"/>
              <img class="p-2" width="50px" src="/img/icons/agodacuadrado.png" alt="logo de agoda" style="filter: drop-shadow(rgba(0, 0, 0, 0.7) 1px 1px 4px);"/>
            </div>
          </div>
          <span class="legal" style="line-height: 17px" id="subtitles">{{ eslogan }}</span>
        </div>
        <div class="container-links" id="titles">
          <ul class="lista_links">
            <li>
              <a class="text-white pointer" @click="$router.push('/#Nuestros_servicio')">{{ nuestros_servicios }}</a>
            </li>
            <li>
              <a class="text-white pointer" @click="$router.push('presupuesto')">{{ presupuesto }}</a>
            </li>

            <li>
              <a class="text-white pointer" @click="$router.push('/tarifas')">{{ precios }}</a>
            </li>

            <li>
              <a class="text-white pointer" @click="$router.push('/faq')">{{ preguntas_respuestas }}</a>
            </li>
            <li>
              <a class="text-white pointer" @click="$router.push('/login_new')">{{ login_text }}</a>
            </li>
          </ul>
        </div>
        <div class="container-content-contact">

          <div class="content-contact">
          <a href="mailto:admin@linetor.net" id="subtitles"><i class="fa-regular fa-envelope mr-2" aria-hidden="true" id="contact"></i >admin@linetor.net</a>
          <br>
            <a href="https://wa.me/34911592940" id="subtitles"><i class="fa fa-phone mr-2" aria-hidden="true" id="contact"></i>+34667909026</a>
          <br />
          </div>
        </div>
        <div class="bg-footer container-legal justify-content-center">
          <div class="content-legal">
            <span class="content-text" id="subtitles">{{ copytwrite }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      precios: "",
      eslogan: "",
      nuestros_servicios: "",
      presupuesto: "",
      preguntas_respuestas: "",
      login_text: "",
      siguenos_en: "",
      copytwrite: "",
      terminos_condiciones: "",
      politica_privacidad: "",
      politica_cookies: "",
      aviso_legal: "",
      textos: {},
    };
  },
  mounted() {
    window.addEventListener("localStorageChanged", (event) => {
      this.textos = JSON.parse(event.detail.storage);
    });
    this.llenarCampos();
    this.$store.watch(
      (state) => [state.recarga].join(),
      async () => {
        // this.showItemsNumber();
        // this.refreshButton();

        await this.llenarCampos();
        //console.log(this.textos)
      }
    );
  },
  methods: {
    llenarCampos() {
      let textos = JSON.parse(localStorage.getItem("text"));

      this.eslogan = textos.footer_eslogan;
      this.nuestros_servicios = textos.footer_nuestros_servicios;
      this.presupuesto = textos.footer_presupuesto;
      this.preguntas_respuestas = textos.footer_qa;
      this.login_text = textos.login_title;
      this.siguenos_en = textos.footer_rrss;
      this.copytwrite = textos.footer_copywrite;
      this.terminos_condiciones = textos.footer_terminos_condiciones;
      this.politica_privacidad = textos.footer_politica_privacidad;
      this.politica_cookies = textos.footer_politica_cookies;
      this.aviso_legal = textos.footer_aviso_legal;
      this.precios = textos.precios_titulo;
    },
    goto(id) {
      if (id == 1) this.$router.push("/terms");
      if (id == 2) this.$router.push("/politics");
    },
  },
  watch: {
    textos(newV) {
      this.llenarCampos();
    },
  },
};
</script>

<style>
.footer-height {
  height: 100%;
}
.bg-footer {
  /* background-color: #05102e;*/
  background-color: #2b2a2f;
}
.cursor {
  cursor: pointer;
}
.container-modify {
  padding: 18px 18px;
  width: 100%;
  height: 100%;
}

.container-content {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  height: 100%;
}
.container-content-contact {
  text-align: left;
}
.container-content-aff {
  display: flex;
  flex-direction: column;
  gap: 18px;
}
.container-logos {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 8px;
}
.container-logos > img {
  max-width: 150px;
  max-height: 76px;
}
.aff {
  display: inline-block;
}
.legal {
  color: white !important;
  opacity: 0.8;
  font-size: 14px;
  text-align: left;
  line-height: 24px;
}
.container-links {
  padding: 0 8px;
}
.container-links ul {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}
.container-links ul li {
  display: flex;
  list-style: none;
  margin-bottom: 8px;
  font-size: 13px;
  margin-bottom: 16px;
}

.container-content-contact > p {
  color: white !important;
  font-weight: bold;
  font-size: 12px;
}
hr {
  background-color: #555555;
  opacity: 0.7;
  widows: 100%;
}
.container-legal {
  position: relative;
  height: 36px;
  display: grid;
  grid-column-start: 1;
  grid-column-end: 4;
  justify-content: start;
  align-items: center;
  border-top: 1px solid rgba(131, 131, 131, 0.7);
  margin-top: 12px;
}
.content-legal {
  display: flex;
  align-items: center;
  gap: 18px;
}
.content-legal > span {
  font-size: 14px;
  color: white !important ;
}
.content-legal ul {
  position: absolute;
  right: 0;
  display: flex;
  gap: 25px;
  padding: 0;
  margin: 0;
}
.content-legal ul li {
  list-style: none;
  font-size: 12px;
}
.content-legal ul li a {
  color: white !important;
  cursor: pointer;
  border-bottom: 1px solid rgb(255, 255, 255, 0);
  transition: border 100ms linear;
}
.content-legal ul li a:hover {
  border-bottom: 1px solid rgb(255, 255, 255, 1);
}
@media (max-width: 993px) {
  .lista_links {
    display: grid !important;
    grid-template-columns: 1fr 1fr !important;
    margin-top: 8px !important;
  }
  .container-content {
    max-height: 458px;
  }
  .container-content-aff {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footer-height {
    max-height: 545px;
  }
  .container-content {
    display: flex;
    flex-direction: column;
  }
  .legal {
    margin-bottom: 16px;
  }
  .container-content-contact {
    margin-bottom: 16px;
  }
  .container-legal {
    justify-content: center;
    margin-bottom: 164px;
  }
  .content-legal {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
  }
  .content-legal ul {
    position: static;
  }
  a {
    text-decoration: none !important;
  }
}
@media (max-width: 520px) {
  .footer-height {
    height: 600px;
    max-height: 664px;
  }
  .container-logos {
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 366px) {
    .footer-height {
      height: 709px;
      max-height: 723px;
    }
    .lista_links {
      display: grid !important;
      grid-template-columns: 1fr !important;
      margin-top: 8px !important;
    }
  }
}

#contact{
  color: #818181;
}

/* Fonts Styles */
#titles {
  font-family: 'Merriweather', serif !important;
}

#subtitles{
  font-family: 'Sk-Modernist', sans-serif !important;
}
/* Fonts Styles */

/* ajuste de copy Styles */
@media(min-width: 581px) {
  .content-text{
    margin-top: 7%;
  }
}

@media(max-width: 580px) {
  .content-text{
    margin-top: 5%;
    margin-left: 12%;
  }
  .legal{
    justify-content: center;
    text-align: center;
    margin-left: 6%;
  }
  .container-links{
    margin-left: 5%;
  }

  .container-content-contact {
    margin-left: 10%;
  }
  .content-contact {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .content-contact a {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
/* ajuste de copy Styles */


</style>
